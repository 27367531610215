import { Variant } from '@framework/api/types';
import getIsNotExpired from './get-is-not-expired';

// Concept
// if > 1 countdown variant
// if 0 index oos/timer has run out move to next countdown
// if both oos (or timer has run out) move to index 0
// if 1 countdown variant
// index 0 forever even if oos
// if no countdown, default

// Prop Details
// countdownVariants => filtered list of only countdown variants
// allVariants => all the prospective variants

const getDefaultCountdownVariant = (
  countdownVariants: Variant[],
  allVariants: Variant[]
): Variant => {
  const countdownVars = countdownVariants?.filter(
    itm => itm?.showCountdownTimer
  );
  let defaultVar;
  if (countdownVars?.length === 1) {
    const isInStock = countdownVars.find(item => !item?.isOutOfStock);
    const indexOfInStock = allVariants.indexOf(isInStock || countdownVars[0]);
    const selectedIndex = indexOfInStock >= 0 ? indexOfInStock : 0;
    defaultVar = allVariants[selectedIndex];
  } else {
    const isInStock = countdownVars.find(
      item => !item?.isOutOfStock && getIsNotExpired(item?.countdownEndDate)
    );

    const indexOfInStock = allVariants.indexOf(isInStock || countdownVars[0]);
    const selectedIndex = indexOfInStock >= 0 ? indexOfInStock : 0;
    defaultVar = allVariants[selectedIndex];
  }

  return defaultVar;
};

export default getDefaultCountdownVariant;
