/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  countdownData?: {
    showCountdown: boolean;
    countdownEndDate?: {
      days: number | string;
      hours: number | string;
      minutes: number | string;
      seconds: number | string;
      initialized: boolean;
      isExpired?: boolean;
    };
  };
  customContainerClass?: string;
  isOutOfStock?: boolean;
  hasBackground?: boolean;
};

const CountdownTimer: FC<Props> = ({
  countdownData,
  customContainerClass,
  isOutOfStock,
  hasBackground,
}) => {
  const DEAL_LABEL = t('label_deal_ends');
  const DEAL_ENDS_SOON = t('label_ending_soon');

  const timeCheck = countdownData?.countdownEndDate?.days === '00';

  const stylesToShow = customContainerClass || styles.countdownContainer;

  const getLabel = (): string => {
    if (!isOutOfStock && !countdownData?.countdownEndDate?.isExpired) {
      return `${DEAL_LABEL} ${countdownData?.countdownEndDate?.hours}:${countdownData?.countdownEndDate?.minutes}:${countdownData?.countdownEndDate?.seconds}`;
    }

    if (!isOutOfStock && countdownData?.countdownEndDate?.isExpired) {
      return `${DEAL_ENDS_SOON}`;
    }

    if (isOutOfStock && countdownData?.countdownEndDate?.isExpired) {
      return `${DEAL_LABEL} 00:00:00`;
    }

    if (isOutOfStock && !countdownData?.countdownEndDate?.isExpired) {
      return `${DEAL_LABEL} ${countdownData?.countdownEndDate?.hours}:${countdownData?.countdownEndDate?.minutes}:${countdownData?.countdownEndDate?.seconds}`;
    }

    return null;
  };

  return timeCheck && countdownData?.showCountdown ? (
    <div className={stylesToShow}>
      <p className={styles.countdown(hasBackground)}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>{' '}
        <span>{getLabel()}</span>
      </p>
    </div>
  ) : null;
};

export default memo(CountdownTimer);
