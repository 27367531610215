import { classnames } from '@tailwindcss-classnames';

const smallButtonText = classnames('text-xs', 'md:text-base');
export const buttonLabel = (isSmall: boolean): string =>
  classnames({ [smallButtonText]: isSmall });

export const descGrid = classnames('grid', 'gap-0', 'grid-cols-2', 'items-end');
export const fullWidth = classnames('w-full');

const borderTop = classnames('border-t', 'dark:border-nzxt-dark-grey-200');
export const extendedDetailsWrapper = (withBorder: boolean): string =>
  classnames('grid', 'items-center', 'pt-3', {
    [borderTop]: withBorder,
  });
