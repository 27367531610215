import { classnames } from '@tailwindcss-classnames';

const darkCopy = classnames('text-nzxt-light-grey-700');
const defaultCopy = classnames('text-nzxt-light-grey-600', 'dark:text-white');

// countdown
export const countdown = (hasBackground: boolean): string =>
  classnames(
    'font-semibold',
    'text-xs',
    'flex',
    'space-x-1',
    'items-start',
    'md:items-center',
    {
      [darkCopy]: hasBackground,
      [defaultCopy]: !hasBackground,
    }
  );

export const countdownContainer = classnames('flex', 'col-span-2', 'w-full');
