import { memo, useMemo, FC, ReactNode, CSSProperties } from 'react';
import cx from 'clsx';
import * as styles from './styles';

type Props = {
  fullBleed?: boolean;
  children?: ReactNode;
  className?: string;
  id?: string;
  style?: CSSProperties;
  dataTestId?: string;
};

const ContentWrapper: FC<Props> = ({
  children,
  className,
  id,
  fullBleed,
  style,
  dataTestId,
  ...rest
}) => {
  const composedClassName = useMemo(
    () => cx({ [styles.contentWrapper]: !fullBleed }, className),
    [className, fullBleed]
  );

  return (
    <div
      className={composedClassName}
      data-test-id={dataTestId}
      id={id}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
};

export default memo(ContentWrapper);
