import { classnames } from '@tailwindcss-classnames';

export const contentWrapper = classnames(
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'xl:px-20'
);

export default contentWrapper;
